.profile_card {
    margin-bottom: 26px;
}

.profile_card-img {
    margin-right: 20px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.profile_card-info {
    margin-left: 10px;
}

.profile_card-name {
    width: 136px;
    height: 67px;
    font-size: 20px;

}
.profile_card-companyName {
    font-size: 16px;
}

.news_banner {
    width: 315px;
    margin-top: 50px;
}

.profile_card-number {
    font-size: 16px;
    color: rgba(131, 134, 144, 1);
}

.profile_container {
    margin-top: 50px;
    margin-bottom: 50px;
    min-height: 75vh;
}

.profile_wrapper {
    width: 100%;
    margin-left: 50px;
}

.profile_links {
    margin-top: 38px;
}

.profile_link {
    position: relative;
    width: 192px;
    height: 54px;
    border-radius: 10px;
    border: 1px solid rgba(236, 236, 236, 1);
    transition: .25s;
}

.profile_link:hover {
    background-color: rgba(246, 246, 246, 1);
}

.profile_link + .profile_link {
    margin-left: 89px;
}

.profile_link.active {
    background-color: rgba(181, 183, 189, 1);
    color: white;
}

.profile_link .noticeBadge {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -10px;
    margin-right: 15px;
    color: white;
    font-size: 0.75rem;
    padding: 3px 7px 3px 7px;
    background-color: red;
    border-radius: 50%;
}

.profile-payment_notice {
    margin-top: 10px;
    width: 100%;
    height: 100px;
    padding: 15px;
    display: flex;
    background: rgba(241, 224, 90, 0.5);
    border-radius: 15px;
}

.header .payment_button {
    align-self: flex-end;
    padding: 8px;
    border-radius: 15px;
    font-size: 14px;
    color: #fff;
    background: #6fa660;
}

.header .payment_button:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.175);
}

.profile-payment_notice .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.profile-payment_notice .header a .title {
    font-size: 18px;
    margin-left: 15px;
}

.profile-payment_notice .header a .price {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.profile-payment_notice .header img {
    border-radius: 50%;
    object-fit: cover;
    height: 70px;
    width: 70px;
}

