.modal {
    width: 100%;
    height: 100%;
    background-color: rgba(47, 47, 47, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: .5s;
    pointer-events: none;
}

.modal.activeModal {
    opacity: 1;
    pointer-events: all;
}

.modal_content {
    text-align: center;
    border-radius: 20px;
    padding: 60px;
    background-color: white;
    z-index: 2;
    transform: scale(0.5);
    transition: .4s;
}

.modal_content.activeModal {
    transform: scale(1);
}


.modalCat {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: right;
    background-color: rgba(47, 47, 47, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    opacity: 0;
    transition: .5s;
    pointer-events: none;
}

.modalCat.activeModalCat {
    opacity: 1;
    pointer-events: all;
    z-index: 4;
}

.modalCat_content {
    position: absolute;
    top: 160px;
    right: 209px;
    width: 1332px;
    height: 730px;
    text-align: center;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 25px 0 53px 19px;
    background-color: white;
    z-index: 3;
    transform: scale(0.5);
    transition: .4s;
}

.modalCat_content.activeModalCat {
    transform: scale(1);
}

.rating_modal {
    width: 486px;
    height: 682px;
    text-align: left;
    overflow-y: auto;
}

.rating_modal-title {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 30px;
}

.description_modal {
    width: 700px;
    height: 682px;
    text-align: left;
    overflow-y: auto;
}

.description_modal-title {
    font-size: 32px;
    font-family: OpenSansBold;

}

.description_modal-text {
    font-family: OpenSansRegular;
    white-space: pre-line
}

.title_characteristic {
    font-size: 14px;
    font-family: OpenSansBold;
}

.phone_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 327px;
    height: 80px;
    text-align: left;
}

.categoryModal-categories {
    margin-right: 10px;
    overflow: auto;
}

.category_link {
    width: 278px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    text-align: left;
    border-radius: 10px;
    cursor: pointer;
    transition: .25s;
}

.category_link-text {
    width: 213px;
    margin-right: 10px;
    margin-right: 10px;
    font-size: 16px;
}

.category_link-icon {
    width: 5px;
    height: 10px;
}

.category_link:hover {
    background-color: #F6F6F6;
}

.category_link + .category_link {
    margin-top: 5px;
}

.reviews_block {
    margin-top: 40px;
}

.reviews_cost {
    font-size: 18px;
    color: #B5B7BD;
}

.reviews_list {
    margin-top: 30px;
}

.review_person {
    display: flex;
}

.review_person + .review_person {
    margin-top: 40px;
}

.review_avatar {
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

.review_info {
    display: flex;
    flex-direction: column;
}

.review_avatar-img {
    width: 47px;
    height: 48px;
    min-width: 45px;
    min-height: 45px;
}

.review_info-name {
    font-size: 16px;
    color: #070C20;
    font-family: OpenSansBold;
    margin-bottom: 5px;
}

.review_date {
    margin-bottom: 10px;
    font-size: 14px;
    color: #B5B7BD;
}

.review_text {
    font-size: 14px;
    color: #838690;
    margin-top: 20px;
}

.rating_cost {
    margin-right: 20px;
    font-size: 32px;
    font-family: OpenSansBold;
}

.rating_btn {
    width: 182px;
    height: 38px;
    border-radius: 10px;
    background-color: #161616;
    color: white;
    font-size: 14px;
    margin-left: 45px;
    transition: .5s;
}

.rating_btn:hover {
    background-color: #404040;
}

/* Write review */

.write_review {
    width: 530px;
    height: 580px;
}

.write_review-btn {
    font-size: 18px;
    margin-left: 15px;
    font-family: OpenSansSemiBold;
}

.back_btn {
    margin-bottom: 50px;
}

.write_review-main {
    text-align: left;
}

.write_review-title {
    font-size: 32px;
    color: #070C20;
    margin-bottom: 10px;
    font-family: OpenSansBold;
}

.write_review-subtitle {
    font-size: 14px;
    color: #838690;
    margin-bottom: 30px;
}

.write_review-stars {
    margin-left: 30px;
    font-size: 16px;
    color: #B5B7BD;
}

.write_review-textarea {
    width: 530px;
    height: 234px;
    border-radius: 10px;
    background-color: #ECECEC;
    padding: 16px 20px;
    margin-top: 40px;
}

.write_review-submit {
    width: 192px;
    height: 54px;
    background-color: #161616;
    color: white;
    border-radius: 10px;
    margin-top: 40px;
    transition: .5s;
}

.write_review-submit:hover {
    background-color: #404040;
}

.modal_subcategory + .modal_subcategory {
    margin-left: 80px;
}

.objects_list {
    margin-bottom: 10px;
    width: 156px;
    text-align: left;
    align-items: end;
}

.categoryModal_subcategory {
    overflow: auto;
    margin-left: 60px;
    width: 900px;
    height: 600px;
}

.modal_subcategory-icon {

    margin-bottom: 6px;
}

.modal_subcategory-title {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 20px;
    text-align: left;
}

.modal_subcategory-subtitle {
    font-family: OpenSansBold;
    width: 125px;
}

.modal_subcategory-object {
    text-align: left;
    font-size: 14px;
    color: #161616;
    margin-bottom: 5px;
}

.modal_subcategory-object:hover {
    opacity: 0.5;
}

.categoryModal-row + .categoryModal-row {
    margin-top: 40px;
}

.hide_title {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 5px;

}

.hide_subtitle {
    font-size: 20px;
    color: #838690;
}

.hide_btn {
    width: 93px;
    height: 54px;
    border-radius: 10px;
    font-size: 16px;
    font-family: OpenSansBold;
    margin-top: 30px;
    margin-right: 10px;
    transition: .5s;
}

.hide_ad-btn + .hide_ad-btn {
    margin-left: 5px;
}

.hide_ad-yes {
    background-color: #161616;
    color: white;

}

.hide_ad-yes:hover {
    background-color: #404040;
}

.hide_ad-no {
    border: 1px solid #ECECEC;
}

.hide_ad-no:hover {
    background-color: #F6F6F6;
}

.editModalImage-btn {
    position: absolute;
    right: 10px;
    width: 35px;
    height: 35px;
    border: 1px solid black;
    z-index: 1;
    color: white;
}

.editModalRemove {
    top: 60px;
    font-size: 1.1rem
}

.editModalScalePlus {
    top: 100px;
    font-size: 1.5rem
}

.editModalScaleMinus {
    top: 140px;
    font-size: 1.4rem
}

.editModalRotate {
    top: 180px;
    font-size: 1.1rem
}

.editProfile-changeAvatar {
    margin-top: 15px;
    font-weight: bold;
    color: rgba(131, 134, 144, 1);
}

.editModalImage-btn:hover {
    box-shadow: 1px 1px 12px 0;
}

.editProfile-label {
    font-size: 18px;
    font-family: OpenSansBold;
}

.editProfile-input {
    background-color: #ECECEC;
    width: 237px;
    height: 47px;
    border-radius: 10px;
    padding: 15px;
    margin-top: 12px;
    margin-bottom: 15px;
    position: relative;
}

.editProfile-loading {
    position: absolute;
    text-align: center;
    background: rgba(7, 12, 32, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 20px;
}

.editProfile-btns {
    margin-top: 38px;
}

.editProfile-save {
    width: 192px;
    height: 54px;
    background-color: rgba(22, 22, 22, 1);
    color: white;
    font-weight: bold;
    border-radius: 10px;
}

.editProfile-cancel {
    background-color: transparent;
    border: 1px solid rgba(236, 236, 236, 1);
    border-radius: 10px;
    width: 192px;
    height: 54px;
}

.profileEdit-img {
    width: 116px;
    height: 116px;
    border-radius: 50%;
}

/* AddAppealModal */

.add_appeal-title {
    font-size: 32px;
}

.add_appeal-select {
    width: 100%;
    background-color: rgba(236, 236, 236, 1);
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 27px;
    outline: none;
}

.add_appeal-textarea {
    width: 100%;
    height: 196px;
    border-radius: 10px;
    margin-top: 43px;
    resize: none;
    padding: 15px;
    background-color: rgba(236, 236, 236, 1);
}

.add_appeal-btn {
    width: 192px;
    height: 54px;
    background-color: rgba(22, 22, 22, 1);
    color: white;
    border-radius: 10px;
    margin-top: 60px;
}

/* Filter (Edited)  */

.Edited_appeal-select{
    position: relative;
    margin-top: 27px;
}

.Edited_filter-header {
    width: 250px;
    font-size: 16px;
    padding: 15px;
    box-shadow: 0px 5px 35px 0px rgba(38, 38, 38, 0.1);
    background-color: rgba(236, 236, 236, 1);
    border-radius: 10px;
    height: 30px;
    cursor: pointer;
    z-index: 0;

}

.Edited_filter-header-p {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Edited_filter-header:focus {
    border: 1px solid black;
}

.filter_select-body-none {
    z-index: 1;
    display: none;
    padding: 5px;
    margin-top: 5px;
    border-radius: 10px;
    left: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 6px 40px 0px #2626261A;
}

.Edited_filter_select-body {
    background-color: white;
    z-index: 1;
    padding: 5px;
    margin-top: 5px;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: 10px;
    left: 0;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0px 6px 40px 0px #2626261A;
}

.Edited_filter_select-item {
    font-size: 14px;
    height: 100%;
    text-align: left;
    border-radius: 10px;
    cursor: pointer;
    padding: 15px 20px;
    z-index: 3;
    transition: .1s;
}

.Edited_filter_select-item:hover {
    background-color: #F6F6F6;
}

.adress-promation{
    background-color: white;
    padding: 5px;
    width: 300px;
    margin-top: 5px;
    height: fit-content;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 10px;
    box-shadow: 0px 6px 40px 0px #2626261A;
}
.ds-none{
    display: none;
}

/* Filter (Edited)  */


@media (min-width: 1920px) and (max-width: 3000px)  {
    .modalCat_content{
        right: calc(209px + (745 - 209) * ((100vw - 1920px) / (3000 - 1920)));
    }

}

@media (max-width: 1910px) and (min-width: 1200px)  {
    .modalCat_content{
        right: calc(-30px + (200 - -30) * ((100vw - 1200px) / (1960 - 1200)));
    }

}
