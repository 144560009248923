.dialog {
    margin-top: 29px;
}

.message_item {

    height: 156px;
    transition: .25s;
    padding: 20px 30px;
    border-radius: 20px;
    position: relative;
}


.message_item:hover {
    background-color: rgba(246, 246, 246, 1);
}

.message_item + .message_item {
    margin-top: 20px;
}

.messages_header {
    margin-top: 77px;
    margin-bottom: 30px;
}

.message_item-checkbox {
    margin-right: 30px;
}

.message_item-content {
    margin-left: 30px;
}

.message_info {
    height: 116px;
    margin-left: 30px;
    color: black;
}

.message_info-title {
    font-size: 24px;
    color: black;
}

.message_info-text {
    margin-top: 5px;
    font-size: 18px;
    color: black;
}

.message_info-date {
    position: absolute;
    top: 20px;
    right: 30px;
    height: 116px;
    font-size: 14px;
    color: rgba(181, 183, 189, 1);
}

.message_item-img {
    width: 87px;
    height: 116px;
}

.ad_status {
    font-size: 18px;
    margin-top: 20px;
}

.messages_container {
    position: relative;
    background-size: cover;
    background-position: center;
    margin: 20px auto;
    height: 60vh;
    border-radius: 5px;

}

.messages_list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-height: calc(100% - 35px);
    overflow-x: auto;
    padding-top: 30px;
}

.dialogs-receiver {
    flex: 1;
    width: 449px;
    margin-right: auto;
    margin-left: 20px;
    padding: 20px;
    margin-bottom: 50px;
    border-radius: 0 5px 5px 0;
    box-shadow: 0px 6px 40px 0px rgba(38, 38, 38, 0.1);
}

.dialogs-sender {
    flex: 1;
    width: 449px;
    margin-left: auto;
    margin-right: 20px;
    box-shadow: 0px 6px 40px 0px rgba(38, 38, 38, 0.1);
    margin-bottom: 55px;
    padding: 20px;
    border-radius: 10px 0 0 10px;

}

.dialogs-form {
    display: flex;
    position: absolute;
    margin: 15px;
    width: calc(100% - 30px);
    bottom: 0;
    max-height: 75px;
    left: 0;
}

.dialogs-form .dialogs-input {
    background-color: #ECECEC;
    border: 1px solid transparent;
    border-radius: 10px 0 0 10px;
    padding: 16px 30px;
    font-size: 16px;
    max-height: 70px;
    width: 941px;
    height: 54px;
    resize: none;
    overflow: hidden;
}

.dialogs-form .dialogs-textarea:focus {
    outline: none;
}

.dialogs-form .dialogs-sendBtn {
    display: flex;
    width: 100px;
    border: 1px solid #161616;
    border-radius: 0 10px 10px 0;
    background-color: rgba(22, 22, 22, 1);
    justify-content: center;
    align-items: center;
}

.dialogs-textarea {
    width: 90%;
    height: 54px;
    resize: none;
    overflow: hidden;
}


.dialogs-form .dialogs-sendBtn.blocked {

}

.dialogs-form .dialogs-sendBtn:hover {
    cursor: pointer;
    background-color: rgba(64, 64, 64, 1);
}

.dialogs-text {
    display: flex;
    flex-direction: column;
}

.dialogs-time {
    font-size: 14px;
    color: rgba(181, 183, 189, 1);

}

.dialogs-backBtn {
    color: #070C20;
    text-decoration: none;
    font-weight: 600;
}

.dialogs-backBtn:before {
    content: '<';
    margin-right: 5px;
}


.message_item-active {
    color: rgba(16, 201, 90, 1);
}

.message_item-expiration {
    color: rgba(252, 55, 42, 1);
}

.selectedMessages {
    width: 1035px;
    height: 78px;
    background-color: rgba(22, 22, 22, 1);
    color: white;
    border-radius: 20px;
    padding: 12px 12px 12px 50px;
    margin-top: 80px;
    margin-bottom: 228px;
}

.selectedMessages-text {
    font-size: 24px;
}

.dialogs-name {
    position: absolute;
    top: -30px;

}

.dialogs-name-text {
    font-size: 16px;
    font-family: OpenSansBold;
    margin-right: 20px;
    margin-left: -20px;
}

    /* Messages  */
/* Checkbox message  */

/* The chbx_label (container) */
.chbx_label {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chbx_label.active {
    display: block;
    border-radius: 20px;
    height: 100%;
    content: " ";
    width: 100%;
    background: rgba(0,0,0,0.175);
}

  /* Hide the browser's default checkbox */
.chbx_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .chbx_marker {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: solid 1px rgba(181, 183, 189, 1);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* On mouse-over, add a grey background color */
.chbx_label:hover input ~ .chbx_marker {
    background-color: #eee;
  }

  /* When the checkbox is checked, add a blue background */
.chbx_label input:checked ~ .chbx_marker {
    background-color: black;
    border: none;
    padding: 1;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .chbx_marker:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .chbx_label input:checked ~ .chbx_marker:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .chbx_label .chbx_marker:after {
    left: 6px;
    top: 6px;
    width: 7px;
    height: 5px;
    border: solid white;
    border-width: 2px 2px 0 0;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform: rotate(135deg);
  }

/* Checkbox message  */
