.stub_wrapper {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-image: url('../../asserts/stub.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.stub_header img {
    width: 150px;
    height: 150px;
    margin-top: -100px;
}

.stub_text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 22px;
    color: #070C20;
    font-weight: bold;
}

.stub_small_text {
    margin-top: 10px;
    font-size: 13px;
}