.container {
    z-index: 9999;
    position: absolute;
    bottom: 0;
    right: 0;
}
.alertOverlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert {
    background-color: #f0f0f0;
    padding: 20px 40px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
}
.alert::after {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    content: " ";
    height: 5px;
    width: 0; /* Начальное значение width */
    background-color: #10c95a;
    animation: fillWidth 5s linear forwards;
}
@keyframes fillWidth {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
.close {
    position: absolute;
    font-size: 24px;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.text {
    font-size: 20px;
}

.timer {
    height: 2px;
    width: 100%;
    background-color: #10c95a;
}
