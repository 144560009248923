
.myAd {
    margin-top: 50px;
    margin-bottom: 80px;
    position: relative;
}

.myAd_grey {
    position: absolute;
    top: -40px;
    left:-30px;
    width: 1065px;
    height: 413px;
    background-color: rgba(7, 12, 32, 0.09);
    border-radius: 20px;
    z-index: 2;
}

.myAd + .myAd::before {
    display: block;
    content: '';
    width: 1035px;
    height: 1px;
    background-color: rgba(181, 183, 189, 1);
    margin-top: 80px;
    margin-bottom: 80px;
}

.myAd_container {
    position: relative;
}

.profile_content_ad {
    margin-top: 77px;
}

.actions_row-first {
    margin-bottom: 25px;
}

.actions_row {
    width: 153px;
}

.actions_row + .actions_row {
    margin-top: 15px;
}

.myAd_actions {
    font-size: 14px;
    
}

.myAd_actions_archive {
    margin-top: 14px;
}

.myAd_actions-title {
    text-align: left;
    color: rgba(7, 12, 32, 1);
}

.myAd_actions-value {
    text-align: right;
    color: rgba(181, 183, 189, 1);
}

.myAd_btn-edit {
    margin-top: 59px;
    margin-bottom: 59px;
}

.myAd_img {
    width: 248px;
    height: 333px;
    margin-left: 51px;
}

.myAd_img-image {
    width: 248px;
    height: 333px;
    border-radius: 20px;
    object-fit: cover;
}

.myAd_description {
    width: 526px;
    font-size: 14px;
    margin-left: 35px;
}

.myAd_title {
    width: 400px;
    font-size: 24px;
    color: rgba(7, 12, 32, 1);
    margin-bottom: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.myAd_price {
    font-size: 32px;
    color: rgba(7, 12, 32, 1);
    margin-bottom: 20px;
}

.myAd_ad_description {
    width: 405px;
    height: 142px;
    overflow: hidden;
    color: rgba(131, 134, 144, 1);
    line-height: 20.68px;
}

.myAd_open_description {

    text-align: right;
    margin-top: 41px;
    font-size: 16px;
}

.myAd_type {
    font-family: 'OpenSansRegular';
    font-size: 18px;
    color: rgba(181, 183, 189, 1);
}

.myAd_actions_favorite-status {
    font-size: 18px;
    color: rgba(16, 201, 90, 1);
    margin-bottom: 20px;
}

.myAd_actions_favorite-category {
    color: rgba(131, 134, 144, 1);
    margin-bottom: 52px;
    font-size: 14px;
}
.myAd_actions_favorite-category.edit{
    position: relative;
    z-index: 3;
}

.myAd_actions_favorite-time {
    margin-top: 52px;
    color: rgba(181, 183, 189, 1);
    margin-bottom: 5px;
}

.myAd_actions_favorite-views {
    color: rgba(181, 183, 189, 1);
}

.absolute {
    position: absolute;
    top: 5px;
    right: 0;
}

.favorite_filter {
    margin-top: 77px;
    padding-bottom: 50px;
}

.myAd_grey-red {
    color: rgba(252, 55, 42, 1);
}

.profile_contend-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 242px;
    height: 47px;
    background-color: rgba(236, 236, 236, 1);
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 14px;
}

.ads_filter_select {
    position: relative;
    width: 237px;
}

.ads_filter-header {
    font-size: 16px;
    padding: 15px;
    box-shadow: 0px 5px 35px 0px rgba(38, 38, 38, 0.1);
    border-radius: 10px;
    width: 237px;
    height: 47px;
    cursor: pointer;
    z-index: 0;
}

.ads_filter-header:focus {
    border: 1px solid black;
}

.filter_select-body-none {
    z-index: 1;
    display: none;
    padding: 5px;
    margin-top: 5px;
    border-radius: 10px;
    left: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 6px 40px 0px #2626261A;
}

.ads_filter_select-body {
    background-color: white;
    z-index: 3;
    padding: 5px;
    margin-top: 5px;
    border-radius: 10px;
    left: 0;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0px 6px 40px 0px #2626261A;
}

.filter_select-item {
    font-size: 14px;
    width: 227px;
    height: 47px;
    border-radius: 10px;
    cursor: pointer;
    padding: 15px 20px;
    z-index: 3;
}

.content_ads_filter {
    margin-right: 30px;
}


