.container {
    margin-top: 10px;
    width: 100%;
    height: 100px;
    padding: 15px;
    display: flex;
    background: rgba(241, 224, 90, 0.5);
    border-radius: 15px;
}
.button {
    align-self: flex-end;
    padding: 8px;
    border-radius: 15px;
    font-size: 14px;
    color: #fff;
    background: #6fa660;
}
.buttonRemove {
    align-self: flex-end;
    padding: 8px;
    border-radius: 15px;
    font-size: 14px;
    color: #fff;
    background: #ad0303;
}
.buttonRemove,
.button:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.175);
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.title {
    font-size: 18px;
    margin-left: 15px;
}
.price {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}
.header img {
    border-radius: 50%;
    object-fit: cover;
    height: 70px;
    width: 70px;
}