
.btn-connect + .btn-connect {
    margin-left: 15px;
}

.stroke {
    font-family: OpenSansSemiBold;
    color: #838690;
    transition: .25s;
    font-size: 16px;
}

.stroke:hover {
    color: black;
}

.activeAd {
    width: 153px;
    height: 54px;
    border-radius: 10px;
    font-family: OpenSansSemiBold;
    background-color: black;
    color: white;
    transition: .25s;
    font-size: 16px;
}

.activeAd:hover {
    background-color: rgba(64, 64, 64, 1);
}

.trashBlack {
    width: 153px;
    height: 54px;
    background-color: rgba(102, 105, 111, 1);
    border-radius: 10px;
}

.trashBlack:hover {
    background-color: rgba(72, 74, 77, 1);
}

.phone {
    width: 93px;
    height: 54px;
    background: black;
    border-radius: 10px;
    border: 1px solid rgba(236, 236, 236, 1);
    transition: .25s;
}

.phone_l {
    margin-right: 5px;
    display: flex;
    padding: 10px 20px;
    border-radius: 10px;
    background: black;
    border: 1px solid rgba(236, 236, 236, 1);
    transition: .25s;
}
.phone_l:hover,
.phone:hover {
    background-color: rgba(64, 64, 64, 1);
}

.phoneTall {
    width: 153px;
    height: 54px;
    background-color:rgba(22, 22, 22, 1) ;
    border-radius: 10px;
    transition: .25s;
}

.phoneTall:hover {
    background-color: rgba(64, 64, 64, 1);
}

.message {
    width: 93px;
    height: 54px;
    background: white;
    border-radius: 10px;
    border: 1px solid rgba(236, 236, 236, 1);
    transition: .25s;
}
.message_l {
    display: flex;
    padding: 10px 20px;
    border-radius: 10px;
    background: white;
    border: 1px solid rgba(236, 236, 236, 1);
    transition: .25s;
}
.message_l,
.message:hover {
    background-color: rgba(246, 246, 246, 1);
}

.show_phone {
    width: 192px;
    height: 54px;
    color: white;
    background-color: black;
    border-radius: 10px;
    transition: .25s;
    font-family: OpenSansSemiBold;
    margin-right: 10px;
}

.show_phone:hover {
    background-color: rgba(64, 64, 64, 1);
}

.in_card {
    display: none;
    position: absolute;
    top: 45px;
    right: 45px;
}

.like_ads {
    display: none;
    position: absolute;
    bottom: 10px;
    right: 30px;
    background-color: rgba(255, 255, 255, 1);
    width: 182px;
    height: 38px;
    color: rgba(131, 134, 144, 1);
    z-index: 1;
    border-radius: 10px;
}

.like_ads:hover {
    background-color: rgba(246, 246, 246, 1);
}

.edit {
    width: 153px;
    height: 54px;
    background: white;
    border-radius: 10px;
    border: 1px solid rgba(236, 236, 236, 1);
    transition: .25s;
}
.editProfile {
    height: 54px;
    transition: .25s;
}

.edit:hover {
    background-color: rgba(246, 246, 246, 1);
}




