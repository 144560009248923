.card {
    position: relative;
    color: black;
    padding: 20px;
    border-radius: 20px;
    transition: .25s;
    cursor: pointer;
}

.card:hover {
    box-shadow: 0px 6px 40px 0px rgba(38, 38, 38, 0.22);
}

.card:hover .in_card {
    display: block;
}

.card .in_card.active {
    display: block !important;
}


.card_content-img {
    border-radius: 20px;
    object-fit: cover;
}

.card_content-text {
    margin-top: 8px;
}

.card_content-title {
    margin-top: 20px;
    font-weight: 600;
    font-family: 'OpenSansSemiBold';
}

.card_content-price {
    font-size: 24px;
    font-family: 'OpenSansSemiBold';
}

.thin {
    width: 1400px;
    height: 76px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.thin:hover {
    background-color: white;
}

.thin .card_content-img {
    width: 1400px;
    height: 76px;
}

.card_content-title, .card_content-price {
    color: #070C20;
}

.card_content-address {
    color: #838690;
}

.s {
    width: 355px;
    height: 585px;
}

.s .card_content-img {
    width: 315px;
    height: 417px;
}

.s .card_content-title {
    font-size: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    margin-bottom: 10px;
}

.s .card_content-date {
    font-size: 14px;
    color: rgba(181, 183, 189, 1);
}

.s .card_content-address {
    font-size: 14px;
    color: rgba(131, 134, 144, 1);
    margin-bottom: 5px;
    line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.s .card_content-price {
    font-size: 24px;
    /* font-family: OpenSansBold; */
}

.l {
    width: 730px;
    height: 585px;
}

.l .card_content-img {
    width: 690px;
    height: 417px;
}

.l .card_content-title {
    width: 450px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.l .card_content-date {
    font-size: 14px;
    color: rgba(181, 183, 189, 1);
}

.l .card_content-address {
    font-size: 14px;
    color: rgba(131, 134, 144, 1);
    margin-bottom: 5px;
    line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.l .card_content-price {
    margin-top: 7px;
    font-size: 32px;
    /* font-family: OpenSansBold; */
}

.xxl {
    width: 1440px;
    height: 552px;
}

.xxl .card_content-img {
    width: 1400px;
    height: 417px;
}

.xxl .card_content-title {
    font-size: 32px;
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.xxl .card_content-date {
    font-size: 14px;
    color: rgba(181, 183, 189, 1);
}

.xxl .card_content-address {
    margin-right: 50px;
    font-size: 14px;
    color: rgba(131, 134, 144, 1);
    margin-bottom: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.xxl .card_content-price {
    margin-right: 50px;
    font-size: 32px;
    /* font-family: OpenSansBold; */
}


.xl {
    width: 864px;
    height: 478px;
}

.xl .card_content-img {
    width: 824px;
    height: 333px;
}

.xl .card_content-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.xl .card_content-date {
    font-size: 14px;
    color: rgba(181, 183, 189, 1);
}

.xl .card_content-address {
    font-size: 14px;
    color: rgba(131, 134, 144, 1);
    margin-bottom: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.xl .card_content-price {
    margin-right: 50px;
    font-size: 32px;
    /* font-family: OpenSansBold; */
}

.xs {
    width: 288px;
    height: 518px;
}

.xs .card_content-img {
    width: 248px;
    height: 333px;
}

.xs .card_content-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.xs .card_content-date {
    font-size: 14px;
    color: rgba(181, 183, 189, 1);
}

.xs .card_content-address {
    font-size: 14px;
    color: rgba(131, 134, 144, 1);
    margin-bottom: 5px;
    line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.xs .card_content-price {
    margin-top: 15px;
    font-size: 24px;
    /* font-family: OpenSansBold; */
}


.card_service {
    display: flex;
    width: 1132px;
    height: 280px;
    padding: 20px;
    border-radius: 10px;
    transition: .25s;
    cursor: pointer;
    margin-bottom: 26px;
}

.card_service:hover {
    box-shadow: 0px 6px 40px 0px #26262638;
}


.card_service-info {
    margin-left: 35px;
}

.card_service-title {
    font-size: 28px;
    font-family: OpenSansBold;
    margin-bottom: 20px;
    color: black;
}

.card_service-wage {
    font-size: 24px;
    font-family: OpenSansSemiBold;
    margin-bottom: 20px;
    color: black;
}

.card_service-description {
    width: 473px;
    height: 135px;
    font-size: 14px;
    color: #838690;
}

.card_service-btns {
    display: flex;
    margin-left: 37px;
    align-self: end;
}





