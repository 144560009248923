
.card_ad-title {
    width: 1300px;
    /* justify-content: space-between; */
}

.card_ad_wrapper {
    margin-bottom: 105px;
}

.card_ad_address {
    /* margin-left: 20px; */
    width: 519px;
    justify-content: center;
}

.card_ad_name {
    font-family: OpenSansBold;
    font-size: 32px;
    margin-right: 20px;
}

.card_ad_address {
    font-size: 14px;
    color:#B5B7BD;
}

.main_card_img-container:hover .like_ads {
    display: block;
}

.main_card_img {
    object-fit: cover;
    position: relative;
    width: 389px;
    height: 521px;
    margin-left: -20px;
    transition: .25s;
    cursor: pointer;
    border-radius: 20px;
}


.card_img_block {
    position: relative;
    width: 150px;
    height: 525px;
    overflow-y: auto;
}

.card_img_block::-webkit-scrollbar {
     width: 0;
 }


.small_img_card {
    cursor: pointer;
    width: 92px;
    height: 123px;
    border-radius: 20px;
}

.small_img_card + .small_img_card {
    margin-top: 15px;
}

.card_description-container {
    min-width: 420px;
    margin: 0 45px;
}
.card_description {
    max-width: 432px;
    max-height: 421px;
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
}

.show_more-btn {
    margin-top: 69px;
}

.number_time_views {
    color: #B5B7BD;
    margin-bottom: 31px;
    width: 502px;
}

.payment_button {
    align-self: flex-end;
    padding: 10px;
    border-radius: 15px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.85);
    background: rgba(241, 224, 90, 0.5)
}

.payment_button:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.175);
}

.card_description-title {
    font-family: "OpenSansBold";
    font-size: 24px;
    margin-bottom: 15px;
    color: #070C20;
}

.card_description-text {
    width: -webkit-fill-available;
    color: #838690;
    font-size: 14px;
    line-height: 20.68px;
    white-space: pre-line
}

.card_description-pre {
    white-space: pre-line;
    margin-bottom: 20px;
    /* height: 100%; */
    font-family: OpenSansRegular;
    overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      line-clamp: 3;
      box-orient: vertical;

}

.card_service-img {
    border-radius: 15px;
}

.service_description-pre {
    width: 473px;
    height: 90px;
    overflow: hidden;
    white-space: pre-line;
    font-family: OpenSansRegular;
}

.card_info {
    margin-left: 45px;
    padding: 30px 45px;
    width: 389px;
    height: 521px;
    box-shadow: 0px 4px 50px 0px rgba(38, 38, 38, 0.1);
    border-radius: 20px;
}

.card_info-price {
    font-family: OpenSansBold;
    font-size: 32px;
    color: rgba(7, 12, 32, 1);
    margin-bottom: 30px;
}

.card_info-btn {
    margin-bottom: 30px;
}

.seller_info {
    margin-bottom: 15px;
}
.rating_info {
    margin-bottom: 30px;
    cursor: pointer;
}

.seller_info_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.seller_info-name {
    font-size: 18px;
    color: rgba(7, 12, 32, 1);
    font-weight: bold;
}

.seller_info-name:hover {
    color: rgba(64, 64, 64, 1);
}

.seller_info-date {
    font-size: 14px;
    color: rgba(181, 183, 189, 1);
}

.address_title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.address_text {
    width: 230px;
    color: rgba(131, 134, 144, 1);
}

/* .description_overflow{
    overflow: hidden;

} */

