.catalogBoardPage {
    display: flex;
    width: 1398px;
    min-height: 100vh;
    align-items: flex-start;
}

.catalogBoardPage_categories {
    flex: 1;
    margin-right: 15px;
    max-height: calc(100vh - 80px);
    overflow-x: auto;
}
.catalogBoardPage_cards {
    flex: 4;
}

.catalogBoardPage_categories::-webkit-scrollbar{
    width: 4px;
}
.catalogBoardPage_categories::-webkit-scrollbar-thumb {
    background-color: #8c8c8c;
    box-shadow: inset 1px 1px 10px rgba(0,0,0,0.135);
}

.catalogBoardPage-title {
    width: 1180px;
    font-family: OpenSansBold;
    font-size: 40px;
    margin-bottom: 20px;
    align-self: end;
}

.catalogBoardPage-subtitle {
    width: 1180px;
    font-family: OpenSans;
    font-size: 20px;
    margin-bottom: 20px;
    align-self: end;
}

.catalogBoardPage-subtitle .main {
    text-decoration: underline;
}

.catalogBoardPage-subtitle .active {
    font-family: OpenSansBold;
    text-decoration: none;
    font-size: 18px;
}

/* AUTH */

.auth {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_form {
    position: relative;
    box-shadow: 0px 10px 50px 0px #26262630;
    border-radius: 30px;
    padding: 80px 67px 30px 67px;
}

.auth_form-inputs {
    display: flex;
    flex-direction: column;
}

.reg_company-div + .reg_company-div {
    margin-left: 20px;
}

.auth_form-title {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 20px;
}

.auth_form-label {
    font-size: 18px;
    font-family: OpenSansBold;
}

.auth_form-input {
    background-color: #ECECEC;
    width: 295px;
    height: 47px;
    border-radius: 10px;
    padding: 15px;
    margin-top: 12px;
    margin-bottom: 15px;
    position: relative;
}

.auth_form-eye {
    width: 21px;
    height: 19px;
    position: absolute;
    top: 14px;
    right: 20px;
    cursor: pointer;
}

.miss_password {
    color: #B5B7BD;
    font-family: OpenSansSemiBold;
    font-size: 14px;
    align-self: end;
    line-height: 19.07px;
    cursor: pointer;
}

.auth_form-btns {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.auth_btn {
    width: 192px;
    height: 54px;
    padding: 10px 16px;
    border-radius: 10px;
}
.auth_back_btn {
    height: 54px;
    border-radius: 10px;
}

.signin {
    background-color: #161616;
    color: white;
    margin-bottom: 20px;
}



.signup {
    background-color: white;
    color: #070C20;
    border: 1px solid #ECECEC;

}

.auth_link {
    text-decoration: none;
    background-color: transparent;
}


/*  Create Ad */

.create_ad {
    width: 1400px;
}

.create_ad-title {
    font-size: 40px;
    font-family: OpenSansBold;
    margin-top: 80px;
    margin-bottom: 50px;
}

.create_ad_wrapper {
    width: 1400px;
    border-radius: 20px;
    box-shadow: 0px 10px 50px 0px #2626261A;
    padding: 100px 150px;
}

.create_ad-category {
    margin-bottom: 70px;
}

.create_ad-category-title {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 40px;
}

.create_ad-select {
    width: 260px;
    height: 35px;
    background-color: #ECECEC;
    padding: 6px 10px;
    border-radius: 10px;
}

.create_ad-select + .create_ad-select {
    margin-left: 45px;
}

.create_ad-size-title {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 40px;
    margin-top: 70px;
}

.checked_type_ad {
    border: 1px solid #ffcb04;
}

.upload_photo {
    margin-bottom: 40px;
}

.upload_photo-h1 {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 30px;
    margin-top: 70px;
}

.character-title {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 40px;
    margin-top: 40px;
}

.create_ad-descr {
    margin-top: 30px;
}

.create_ad-descr-title {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 10px;
    margin-top: 50px;
}

.create_ad-descr-subtitle {
    font-size: 15px;
    margin-bottom: 25px;
    color: #838690;
}

.create_ad_descr-text {
    width: 811px;
    height: 300px;
    background-color: #ECECEC;
    border-radius: 10px;
    padding: 25px;
    max-width: 1100px;
}

.create_ad_address {
    width: 524px;
    height: 30px;
    background-color: #ECECEC;
    padding: 15px;
    border-radius: 10px;
}

.create_ad_label {
    font-size: 18px;
    font-family: OpenSansBold;
    margin-right: 205px;
}

.create_ad_phone {
    width: 237px;
    height: 30px;
    background-color: #ECECEC;
    padding: 15px;
    border-radius: 10px;;

}

.create_ad_btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.create_ad_btn {
    width: 192px;
    height: 54px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.created_ad-contact {
    margin-top: 30px;
}

.create_ad_agree {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.create_ad-contact {
    margin-left: 10px;
    font-size: 14px;
}
.create_ad-contact span a {
    text-decoration: underline;
    color: #070C20;
}

.created_ad-radio {
    margin-bottom: 12px;
}

.filters {
    position: relative;
    margin-top: 70px;
    margin-bottom: 70px;
}

.filter_title {
    color: #838690;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: .5s;

}

.filter_title::after {
    content: '';
    display: block;
    width: 80%;
    border-bottom: 1px dotted #838690;
}

.filter_title:hover {
    color: #c4bfbf;
}

.filters_dop {
    transition: all .5s;
    transition: .5s;
}

.filters .buttons {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.filters .search {
    width: 110px;
    height: 45px;
    border-radius: 15px;
    background: #161616;
    color: #fff;
}
.filters .search:hover {
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.175);
}

.filters .reset {
    margin-left: 10px;
    width: 110px;
    height: 45px;
    border-radius: 15px;
    border: 1px solid rgba(0,0,0,0.135);
}
.filters .reset:hover {
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.175);
}

.cropper-bg {
    background-color: white;
    background-image: none;
}

.cropper-modal {
    background-color: gray;
}

.grid_character {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
}

.imgVip {
    width: 368px;
    height: 222px;
    border-radius: 20px;
    cursor: pointer;
}


.editedImage {
    width: 168px;
    height: 222px;
    border-radius: 20px;
    cursor: pointer;
}

.deleteImg_btn {
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 10px;
    background: #fff;
    display: flex;
    border-radius: 50%;
}

.deleteImg_Vipbtn {
    background: #fff;
    display: flex;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 330px;
    padding: 10px;
}

.deleteImg_stBtn {
    position: absolute;
    top: 0px;
    right: 5px;
    padding: 10px;
    background: #fff;
    display: flex;
    border-radius: 50%;
}

.deleteImg_premiumBtn {
    z-index: 1;
    background: #fff;
    display: flex;
    border-radius: 50%;
    position: absolute;
    top: 25px;
    right: 10px;
    padding: 10px;
}

.checkedImg_btn {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 15px;
    left: 20px;
    padding: 10px;
}

.checkedImg_label {
    position: absolute;
    z-index: 1;
    color: black;
    background-color: white;
    top: 2px;
    border-radius: 10px;
    padding: 5px;
    left: 5px;
}

.checkedImg_label.active {
    border: none;
    background-color: #00920c;
    color: #fff;
}

.upload_photo-img {
    width: 132px;
    height: 178px;
    border-radius: 10px;

}

.images-flex_column + .images-flex_column {
    margin-left: 15px;
}

.img_block {
    position: relative;
}

/* support */

.support {
    margin-top: 30px;
    margin-bottom: 40px;
}

.support_item {
    border-radius: 20px;
    padding: 20px;
    width: 1050px;
    height: 156px;
    background-color: rgba(246, 246, 246, 1);
    color: #838690;
    cursor: pointer;
    transition: .5s;
}

.support_item:hover {
    background-color: rgb(231, 230, 230);
}

.appeal + .appeal {
    display: block;
    margin-top: 10px;
}

.support_item-title {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}


.addAppeal-btn {
    width: 200px;
    height: 47px;
    background-color: rgba(22, 22, 22, 1);
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-bottom: 20px;
}

.dialog_appeal-title {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 24px;
}

.dialog_appeal-messages {
    overflow: auto;
    height: 400px;
    padding-top: 10px;
    padding-bottom: 40px;
}

.message_appeal_block {
    width: 434px;
    box-shadow: 0px 6px 40px 0px rgba(38, 38, 38, 0.1);
    padding: 20px;
    font-size: 14px;
    color: rgba(8, 9, 43, 1);

}

.message_appeal_block + .message_appeal_block {
    margin-top: 20px;
}

.left_message {
    align-self: flex-start;
    margin-left: 15px;
}

.right_message {
    align-self: flex-end;
    margin-right: 15px;
}

.support-text {
    font-size: 16px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 10px;
}

.user_text {
    font-size: 16px;
    font-weight: bold;
    margin-right: 15px;
    margin-bottom: 10px;
}

.dialog_appeal-btn {
    width: 200px;
    height: 36px;
    background-color: rgb(82, 82, 82);
    color: white;
    border-radius: 10px;
}

.support-filter {
    width: 250px;
    height: 47px;
    background-color: rgba(236, 236, 236, 1);
    padding: 10px;
    border-radius: 10px;
}

/* Support Page Admin */

.support_wrapper {
    width:1050px;
    height: 600px;
    margin: 100px auto;
}


.mob-input{
    display: none;
}

 /* для элемента label связанного с .mob-input  */
 .mob-input+label {
    display: inline-flex;
    align-items: center;
    user-select: none;

  }
  /* создание в label псевдоэлемента  before со следующими стилями */
  .mob-input+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: solid 1px rgba(181, 183, 189, 1);
    border-radius: 4px;
    margin-right: 0.5rem;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  /* стили при наведении курсора на радио */
  .mob-input:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b3d7ff;
  }

  /* стили для активной радиокнопки (при нажатии на неё) */
  .mob-input:not(:disabled):active::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }

  /* стили для радиокнопки, находящейся в фокусе */
  .mob-input:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
  .mob-input:focus:not(:checked)+label::before {
    border-color: #80bdff;
  }

  /* стили для радиокнопки, находящейся в состоянии checked */
  .mob-input:checked+label::before {
    border: none;
    background-color: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  /* стили для радиокнопки, находящейся в состоянии disabled */
  .mob-input:disabled+label::before {
    background-color: #e9ecef;
  }

