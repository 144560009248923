.create_ad_size-item {
    display: flex;
    align-items: center;
}

.create_ad_size-item + .create_ad_size-item {
    margin-top: 40px;
}

.create_ad_size-checkbox {
    margin-right: 50px;
    display: none;
}


 /* для элемента label связанного с .create_ad_size-checkbox  */
 .create_ad_size-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;

  }
  /* создание в label псевдоэлемента  before со следующими стилями */
  .create_ad_size-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: solid 1px rgba(181, 183, 189, 1);
    border-radius: 4px;
    margin-right: 50px;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  /* стили при наведении курсора на радио */
  .create_ad_size-checkbox:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b3d7ff;
  }

  /* стили для активной радиокнопки (при нажатии на неё) */
  .create_ad_size-checkbox:not(:disabled):active::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }

  /* стили для радиокнопки, находящейся в фокусе */
  .create_ad_size-checkbox:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
  .create_ad_size-checkbox:focus:not(:checked)+label::before {
    border-color: #80bdff;
  }

  /* стили для радиокнопки, находящейся в состоянии checked */
  .create_ad_size-checkbox:checked+label::before {
    border: none;
    background-color: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  /* стили для радиокнопки, находящейся в состоянии disabled */
  .create_ad_size-checkbox:disabled+label::before {
    background-color: #e9ecef;
  }


.create_ad_size-item-description {
    display: flex;
    flex-direction: column;
    width: 473px;
}

.create_ad_size-item-title {
    font-size: 24px;
    font-family: OpenSansBold;
}

.create_ad_size-item-cost {
    font-family: OpenSansSemiBold;
    font-size: 20px;
    color: rgba(181, 183, 189, 1);
    margin-left: 30px;
}

.create_ad_size-item-text {
    color: #838690;
    font-size: 14px;
    line-height: 20.68px;
    margin-top: 20px;
    margin-right: 15px;
}

.create_ad-blocks {
    margin-top: 120px;
}

.create_ad_block {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ECECEC;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 15px;
}



.create_ad-name {
    font-family: OpenSansBold;
    font-size: 18px;

}

.premium {
    width: 535px;
    height: 159px;
}

.vip {
    width: 263px;
    height: 158px;
}

.standart_plus {
    width: 120px;
    height: 158px;
}

.standart {
    width: 95px;
    height: 127px;
}













