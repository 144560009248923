@font-face {
  font-family: "OpenSansRegular";
  src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: url("./fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "OpenSansBold";
  src: url("./fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 400;
}

.fz-24{
    font-size: 24px;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.w-315 {
  width: 315px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-58 {
  margin-right: 58px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.bold {
  font-family: OpenSansBold;
}

.semi_bold {
  font-family: OpenSansSemiBold;
}

.black {
  color: black;
}

.red {
  color: red;
}

img:hover {
  cursor: pointer;
}

body {
  font-family: OpenSansRegular;
  overflow: hidden;
}

.wrapper {
  width: 1400px;
  margin: 0 auto;
}

.relative{
  position: relative;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1518px;
  margin: 0 auto;
}

.container_layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}
.wrapper_layout {
  width: 1536px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;

}

.grey{
  color: grey;
}

.grid {
  display: grid;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.jy-center {
  justify-content: center;
}

.jy-end {
  justify-content: end;
}
.position-absolute-tp{
  position: absolute;
  top: 30px;
  right: 30px;

}
.jy-start{
  display: flex;
  justify-content: flex-start;
}

.jy-space-around {
  justify-content: space-around;
}

.jy-space-between{
  justify-content: space-between;
}

.position-absolute-tp{
  position: absolute;
  top: 30px;
  right: 30px;

}
.jy-start{
  display: flex;
  justify-content: flex-start;
}

.items-center {
  align-items: center;
}

.end {
  align-items: end;
}

.space-between {
  justify-content: space-between;
}

.z0 {
  z-index: 0;
}

.z2 {
  z-index: 2;
}
.l_radius {
  border-bottom-left-radius: 40px;
}

.r_radius {
  border-bottom-right-radius: 40px;
}

.rating-text {
  font-size: 18px;
  font-family:OpenSansBold ;
  margin-right: 10px;
}

.count_grade {
  margin-left: 20px;
  color: #B5B7BD;
  cursor: pointer;
}
.count_grade:hover {
  margin-left: 20px;
  color: black;
}
.opacity-50 {
  opacity: 50%;
}

.character_grid {
  grid-template-columns: repeat(3, 1fr);
}

.up-container {
  position: absolute;
  left: 0;
  width: 142px;
  height: 100vh;
  bottom: 0;
  background: rgb(246,245,245);
  display: flex;
  align-items: start;
  padding: 15px 5px;
}

.up-container_content {
  width: 100%;
  display: flex;
  align-items: center;
}

.up-container_text {
  color: rgba(181, 183, 189, 1);
}

.up-container_img {
  margin-left: 15px;
}

@media screen and (max-width: 1669px) {
  .up-container {
    padding-top: 30px;
    width: 100px;
    height: 250px;
    background: rgba(246,245,245, 0.8);
    bottom: 0;
  }
  .up-container::after {    content: '';
    position: absolute;
    right: 0;
    top: -99px;
    border: 50px solid transparent;
    border-left: 50px solid rgba(246,245,245, 0.8);
    transform: rotate(270deg);
  }
  .up-container_img {
    display: none;
  }
  .up-container_text {
    width: 100%;
  }
}

.timer-container{
  z-index: 3;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 110px;
  left: 0;
}
.close-btn{
  background: url(asserts/icons/close-button.svg);
  background-repeat:no-repeat ;
  height: 15px;
  width: 15px;
  padding: 15px;
  align-items: center;
}
.w-250{
  width: 250px;
}
.w-237{
  width: 237px;
}
.w-1180{
  width: 1180px;
  align-self: end;
}
.h-200{
  height: 200px;
}

.disabled {
  background-color: rgba(128, 128, 128, 0.7)!important;
  color: #000!important;
}

.mr-r{
  margin-right: 30px;
}
@media (max-width:1600px ) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: auto;
    margin: 0 auto;
  }
}
