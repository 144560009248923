.enter_input-title {
    font-size: 16px;
    font-family: OpenSansBold;
    margin-bottom: 10px;
}
.enter_input-input {
    width: 237px;
    height: 30px;
    padding: 15px 10px;
    font-size: 16px;
    background-color: #ECECEC;
    border-radius: 10px;
    margin-bottom: 40px;
    margin-top: 17px;
}

.create_ad-z {
    width: 600px;
}

.checkbox-item + .checkbox-item {
    margin-top: 10px;
}

.checkbox_body {
    overflow-y: auto;
    height: 200px;
    width: 240px;
}

.checkbox_body::-webkit-scrollbar {
    width: 5px;
    margin-right: 10px;
}
.checkbox_body::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: rgba(169, 169, 169, 0.87);
    border-radius: 20px;
}

.select_input {
    width: 237px;
    padding: 6px 10px;
    background-color: #ECECEC;
    border-radius: 10px;
    margin-bottom: 40px;
}

.checkbox_input-checkbox {
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    display: none;
}
  
 /* для элемента label связанного с .checkbox_input-checkbox  */
 .checkbox_input-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
   
  }
  /* создание в label псевдоэлемента  before со следующими стилями */
  .checkbox_input-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: solid 1px rgba(181, 183, 189, 1);
    border-radius: 4px;
    margin-right: 0.5rem;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  
  /* стили при наведении курсора на радио */
  .checkbox_input-checkbox:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b3d7ff;
  }

  /* стили для активной радиокнопки (при нажатии на неё) */
  .checkbox_input-checkbox:not(:disabled):active::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }

  /* стили для радиокнопки, находящейся в фокусе */
  .checkbox_input-checkbox:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
  .checkbox_input-checkbox:focus:not(:checked)+label::before {
    border-color: #80bdff;
  }

  /* стили для радиокнопки, находящейся в состоянии checked */
  .checkbox_input-checkbox:checked+label::before {
    border: none;
    background-color: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  /* стили для радиокнопки, находящейся в состоянии disabled */
  .checkbox_input-checkbox:disabled+label::before {
    background-color: #e9ecef;
  }


