.wrapper {
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.45);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px 40px;
    color: rgba(0, 0, 0, 0.45);
}
.links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.link {
    color: rgba(0, 0, 0, 0.45);
}

.contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.contactContainer a {
    color: rgba(0, 0, 0, 0.55);
    text-decoration: underline;
}
