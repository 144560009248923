.filter_item + .filter_item {
    margin-top: 25px;
}


.filter_label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-family: OpenSansBold;
    z-index: 0;

}

.filter_input {
    background-color: #ECECEC;
    border-radius: 10px;
    padding: 15px;
    width: 116px;
    height: 34px;
}

.filter_input + .filter_input {
    margin-left: 5px;
}


.filter_select {
    position: relative;
    width: 237px;
}

.filter_select-header {
    display: flex;
    align-items: center;
    background-color: #ECECEC;
    padding: 10px;
    border-radius: 10px;
    width: 225px;
    height: 34px;
    cursor: pointer;
    z-index: 0;
    color: rgba(181, 183, 189, 1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.filter_select-header:focus-visible {
    border: 1px solid black;
}

.filter_select-body-none {
    z-index: 1;
    display: none;
    padding: 5px;
    margin-top: 5px;
    border-radius: 10px;
    left: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 6px 40px 0px #2626261A;
}

.filter_select-body {
    max-height: 400px;
    background-color: white;
    overflow: auto;
    z-index: 1;
    padding: 5px;
    margin-top: 5px;
    border-radius: 10px;
    left: 0;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0px 6px 40px 0px #2626261A;
}

.select-item {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
    padding: 15px 20px;
}

.select-item:hover {
    background-color: #F6F6F6;
}

.checkbox_filter {
    margin-right: 10px;
}

.addPage_btn {
    width: 250px;
    height: 50px;
    margin-bottom: 20px;
    background-color: #ECECEC;
    border-radius: 10px;
}

